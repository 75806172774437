import Util from "../util/util";
import LoadingAlert from "app/alerts/loadingAlert";

Util.ifSelector(".login-page", () =>
{
	$("#login-submit").on("click", (event) =>
	{
		Util.setRequiredFieldsToInvalid($(".login-form"));
	});
})

Util.ifSelector(".unarchiving-alert", () => {

	LoadingAlert.showLoadingAlert("Signup Starting Up", "Your demo instance is starting up and " +
		"will take a few minutes.  This screen will disappear once it is ready and available for login.");

	const instanceElement = document.getElementById('instance');
	const instanceId = instanceElement.dataset.instanceId;
	const checkStatus = async () => {
		const response = await $.ajax(`/instance/${instanceId}/status`);
		if(response.status == "Ready") {
			document.location.href = `/login/${instanceId}`;
		}
	};
	setInterval(checkStatus, 5000);
});