export default class Validations
{
	/**
	 * check if email is valid format
	 * @param email - the email to validate
	 * @return true / false indicating the validity of the email
	 */
	public static isEmailValid(email): boolean
	{
		if (email)
		{
			return email.match(/.*@.*\..*/);
		}
		return false;
	}

	/**
	 * check if phone is valid
	 * @param phone - the phone number to check
	 * @return true / false indicating if the phone number is valid
	 */
	public static isPhoneValid(phone): boolean
	{
		if (phone)
		{
			phone = phone.substr(0, 14);
			phone = phone.replace(/[-\(\)\s]/g, '')
			return phone.match(/^\d{10}$/)
		}
		return false;
	}

}