import { MDCTextField } from "@material/textfield/component"
const textareas: MDCTextField[] = [];

$(() =>
{
	const formTextareas = $(".form-textarea .mdc-text-field");

	formTextareas.each((index, area) =>
	{
		textareas.push(new MDCTextField(area));

		if ($(area).parent().hasClass("required"))
		{
			addRequiredHandler($(area).parent());
		}
	});

});

/**
 * Add a handler which manages the visibility of the error text on required fields
 * @param element - jquery form element
 */
function addRequiredHandler(element)
{
	const textarea = element.find("textarea").first();

	let changeHandler = () =>
	{
		if (textarea.val() && textarea.val() !== "")
		{
			element.children(".required-text").each((index, requiredText) =>
			{
				$(requiredText).css("visibility", "hidden");
			});
		}
		else
		{
			element.children(".required-text").each((index, requiredText) =>
			{
				$(requiredText).css("visibility", "visible");
			});
		}
	}

	textarea.on("blur", changeHandler);
}
