
$(async () =>
{
	// look for juno news feeds
	const newsFeeds = $("div[data-juno-news='true']");

	if (newsFeeds.length > 0)
	{
		const junoNews = await $.ajax("/news/juno")
		newsFeeds.html(junoNews)
	}
})