// setup phone formatting watches
$(() =>
{
	const phoneInputs = $(".form-phone-input input");

	phoneInputs.each((idx, input) =>
	{
		$(input).on("keyup", (event) =>
		{
			if (event.key != "Backspace")
			{
				let phoneNumber = $(input).val() as string;

				// extract extension if it exists
				const extension = phoneNumber.substr(14)

				// strip invalid characters
				const rawNumber = phoneNumber.replace(/[^\d]/g, "");
				phoneNumber = rawNumber

				// place the first 3 digits in brackets
				if (rawNumber.length >= 3)
				{
					phoneNumber = phoneNumber.replace(/^(\d{3})/, '($1) ')
				}

				// place a dash between the first six digits and the rest of the number
				if (rawNumber.length >= 6)
				{
					phoneNumber = phoneNumber.replace(/^(\(\d{3}\) \d{3})/, "$1-");
				}

				// limit the numbers length to 10 digits
				phoneNumber = phoneNumber.substr(0, 14);

				$(input).val(phoneNumber + extension);
			}
		})
	});
});
