import { MDCTextField } from "@material/textfield/component"

const inputFields: MDCTextField[] = [];

$(() =>
{
	const formInputs = $(".form-input .mdc-text-field");

	formInputs.each((idx, input) =>
	{
		inputFields.push(new MDCTextField(input));
	});
});
