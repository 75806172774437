import Util from "../util/util";
import Validations from "app/util/validations";
import LoadingAlert from "app/alerts/loadingAlert";

Util.ifSelector(".signup-page", () =>
{
	const formValidationFunc = () =>
	{
		return Util.areAllInputsFilled($("input[name='first_name']"), $("input[name='last_name']"), $("input[name='practice_name']"), $("input[name='province']")) &&
			Validations.isEmailValid($("input[name='email']").val()) &&
			Validations.isPhoneValid($("input[name='phone_number']").val());
	}

	// @ts-ignore
	window.recaptchaSignupCallback = (token) =>
	{
		if (formValidationFunc())
		{
			LoadingAlert.showLoadingAlert("Creating your free trial", "Please don't close your web browser before your request has been submitted. Thank you.");
			window.setTimeout(() =>
			{
				$(".signup-form").submit();
			}, 2000);
		}
		else
		{
			Util.setRequiredFieldsToInvalid($(".signup-form"));

			const phoneField = $("input[name='phone_number']");
			if (!Validations.isPhoneValid(phoneField.val()))
			{
				phoneField.parent(".mdc-text-field").addClass("mdc-text-field--invalid");
			}
		}
	}
});
