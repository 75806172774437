export default class LoadingAlert
{
	/**
	 * show a loading alert over the enter screen
	 * @param title - the title of the alert
	 * @param message - the message to present in the alert
	 * @param showDismissButton - if true a dismiss button is displayed
	 * @param dismissButtonText - the text to show on the dismiss button
	 * @param hideSpinner - true / false, should the spinner spin.
	 * @param showJunoLogo - ture / false, should the juno logo be displayed in the spinner
	 * @param setFields - if true title and message fields will be skipped. Important when fields have been auto filled by server.
	 */
	public static showLoadingAlert(
		title: string,
		message: string,
		showDismissButton = false,
		dismissButtonText = "Dismiss",
		hideSpinner = false,
		showJunoLogo = true,
		setFields = true): void
	{
		const loadingAlert = $(".loading-alert");

		// set text
		if (setFields)
		{
			loadingAlert.find(".title").html(title);
			loadingAlert.find(".text").html(message);
		}

		// show or hide dismiss button
		const dismissButton = loadingAlert.find(".dismiss-btn");
		if (showDismissButton)
		{
			dismissButton.css("display", "block");
			dismissButton.html(dismissButtonText)
		}
		else
		{
			dismissButton.css("display", "none");
		}

		// animate spinner
		const spinner = loadingAlert.find(".spinner");
		if (hideSpinner)
		{
			spinner.addClass("hide-spinner");
		}
		else
		{
			spinner.removeClass("hide-spinner");
		}

		// show / hide juno logo
		if (showJunoLogo)
		{
			spinner.find("img").css("display", "none");
			spinner.find(".juno-logo").css("display", "block");
		}
		else
		{
			spinner.find("img").css("display", "block");
			spinner.find(".juno-logo").css("display", "none");
		}

		// display the loading alert
		loadingAlert.css("display", "inline-block");
		// blur page
		$(".page-content, header, footer").css("filter", "blur(22px)");
	}

	// clears any loading alert being displayed
	public static dismissLoadingAlert(): void
	{
		const loadingAlert = $(".loading-alert");

		loadingAlert.css("display", "none");
		$(".page-content, header, footer").css("filter", "none");
	}
}

$(() => {
	const loadingAlert = $(".loading-alert");

	// if loading alert is marked as initial show. display it on page load.
	if (loadingAlert.hasClass("initial-display"))
	{
		LoadingAlert.showLoadingAlert(null, null, true, "Dismiss", true, false, false);
	}

	// clear alert when dismiss clicked
	loadingAlert.find(".dismiss-btn").on("click", () =>
	{
		LoadingAlert.dismissLoadingAlert();
	})
})