import Validations from "app/util/validations";

export default class Util
{
	// call the callback function if the selector is matched once the page is finished loading
	public static ifSelector(selector: string | Array<string>, callback: Function): void
	{
		$(() =>
		{
			if (selector instanceof Array)
			{
				selector.forEach((sel) =>
				{
					if ($(sel).length > 0)
					{
						callback();
					}
				})
			}
			else
			{
				if ($(selector).length > 0)
				{
					callback();
				}
			}
		})
	}

	// check if all inputs are filled out, returning true or false
	public static areAllInputsFilled(...fields): boolean
	{
		let valid = true;
		fields.forEach((field) =>
		{
			if (field instanceof HTMLInputElement)
			{
				if (!field.value)
				{
					valid = false
				}
			}
			else
			{
				if (!field.val())
				{
					valid = false;
				}
			}
		})

		return valid;
	}

	/**
	 * Dev Note: prime example of why frameworks are good, Angular, React, Vue... how I miss theeeeeeee.
	 *
	 * 	Watch a required field. when it changes recolor the associated required label.
	 * 	said label is identified by an ID of the format <input_id>_required. Ex: last_name_required.
	 * @param selector - the id of the input field to watch
	 * @param validationCallback - additional validation callback. If provided must return true for a field to be considered valid
	 */
	public static handleRequiredFieldChange(selector: string, validationCallback = null): void
	{
		let field = $(selector);
		let changeHandler = () =>
		{
			if (field.val() && field.val() !== "" && (validationCallback === null || validationCallback(field.val())))
			{
				$(selector + "_required").css("color", "var(--color-primary-base)");
				$(`label[for="${selector.replace(/[#.]/, "")}"]`).removeClass("invalid").addClass("valid");
			}
			else
			{
				$(selector + "_required").css("color", "var(--color-red-base)");
				$(`label[for="${selector.replace(/[#.]/, "")}"]`).removeClass("valid").addClass("invalid");
			}
		}

		// call handler right away to handle form auto fill.
		changeHandler();
		field.on("input", changeHandler);
	}

	/**
	 * watch a form changing the submit button from disabled to enabled and back as required.
	 * @param formSelector - a selector that selects the form to watch
	 * @param validationFunction - a function to be run to check if the form is valid
	 */
	public static handleFormStateChange(formSelector: string, validationFunction: Function): void
	{
		const onFormChange = () =>
		{
			if (validationFunction())
			{
				$(`${formSelector} input[type="submit"]`).removeAttr("disabled");
			} else
			{
				$(`${formSelector} input[type="submit"]`).attr("disabled", "disabled");
			}
		}

		onFormChange();
		$(`${formSelector} input`).on("input", onFormChange);
		$(`${formSelector} select`).on("change", onFormChange);
	}

	/**
	 * sets all required fields contained within the form to invalid
	 * @param form - form jQuery object
	 */
	public static setRequiredFieldsToInvalid(form): void {
		// inputs
		form.find(".form-input.required .mdc-text-field").each((idx, formInput) =>
		{
			if ($(formInput).children("input").val() === "")
			{
				$(formInput).addClass("mdc-text-field--invalid");
			}

			// check email inputs
			let email_input = $(formInput).children("input[type='email']");
			if (email_input.length > 0)
			{
				if (!Validations.isEmailValid(email_input.val() as string))
				{
					$(formInput).addClass("mdc-text-field--invalid");
				}
			}
		});

		// textareas
		form.find(".form-textarea.required .mdc-text-field").each((idx, textarea) =>
		{
			if ($(textarea).children("textarea").val() === "")
			{
				$(textarea).addClass("mdc-text-field--invalid");

				// Trigger the display of any error text
				$(textarea).children("textarea").trigger('blur');
			}
		});

		// selects
		form.find(".mdc-select.mdc-select--required").each((idx, formSelect) =>
		{
			if ($(formSelect).siblings("input").val() === "")
			{
				$(formSelect).addClass("mdc-select--invalid");
			}
		})

	}

	/**
	 * check if all required fields are filled in
	 * @param form - the form to check
	 * @return true / false indicating if all fields filled
	 */
	public static areRequiredFieldsFilled(form): boolean {
		let valid = true;
		// inputs
		form.find(".form-input.required .mdc-text-field").each((idx, formInput) =>
		{
			if ($(formInput).children("input").val() === "")
			{
				valid = false;
			}

			// check email inputs
			let email_input = $(formInput).children("input[type='email']");
			if (email_input.length > 0)
			{
				if (!Validations.isEmailValid(email_input.val() as string))
				{
					valid = false;
				}
			}
		});

		// textareas
		form.find(".form-textarea.required .mdc-text-field").each((idx, formInput) =>
		{
			if ($(formInput).children("textarea").val() === "")
			{
				valid = false;
			}
		});

		// selects
		form.find(".mdc-select.mdc-select--required").each((idx, formSelect) =>
		{
			if ($(formSelect).siblings("input").val() === "")
			{
				valid = false;
			}
		})

		return valid;
	}

}
