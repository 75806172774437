import Util from "../util/util";

Util.ifSelector(".marketing-header", () =>
{
	// I think this is setting up the Phone nav menu
	let toggleButton = document.querySelector('.toggle-menu');
	let navBar = document.querySelector('.nav-bar');
	toggleButton.addEventListener('click', () => {
		navBar.classList.toggle('toggle');
	});
});