import {MDCSelect} from "@material/select";

const selectFields: MDCSelect[] = [];

$(() =>
{
	const formSelects = $(".form-select .mdc-select");

	formSelects.each((idx, select) =>
	{
		const newSelect = new MDCSelect(select);
		newSelect.listen("MDCSelect:change", () =>
		{
			$(select).siblings("input").val(newSelect.value);
		});

		newSelect.value = $(select).siblings("input").val() as string;
		selectFields.push(newSelect);
	});
});