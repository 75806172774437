import Util from "../util/util";
import Validations from "app/util/validations";
import LoadingAlert from "app/alerts/loadingAlert";

Util.ifSelector([".request-demo-page", ".landing-page-layout", ".juno-vs-oscar-landing-page-layout"], () =>
{
	// @ts-ignore
	window.recaptchaDemoRequestCallback = (token) =>
	{
		const phoneInput = $("input[name='phone_number']")

		Util.setRequiredFieldsToInvalid($(".demo-form"));

		if (Validations.isPhoneValid(phoneInput.val()) && Util.areRequiredFieldsFilled($(".demo-form")))
		{
			LoadingAlert.showLoadingAlert("Submitting Your Request", "Please don't close your web browser before your request has been submitted. Thank you.");
			window.setTimeout(() => {
				$(".demo-form").trigger("submit");
			}, 2000);
		}
		else if (!Validations.isPhoneValid(phoneInput.val()))
		{
			phoneInput.parent(".mdc-text-field").addClass("mdc-text-field--invalid");
		}
	};
});