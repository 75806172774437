import Util from "app/util/util";
import Validations from "app/util/validations";
import LoadingAlert from "app/alerts/loadingAlert";

Util.ifSelector(".contact-form-page", () =>
{
	// @ts-ignore
	window.recaptchaContactCallback = (token) =>
	{
		Util.setRequiredFieldsToInvalid($(".contact-form"));

		// check if all fields are valid. marking any invalid ones as such.
		if (Util.areRequiredFieldsFilled($(".contact-form")))
		{
			LoadingAlert.showLoadingAlert("Submitting Your Contact Request", "Please don't close your web browser before your request has been submitted. Thank you.");
			window.setTimeout(() =>
			{
				$(".contact-form").trigger("submit");
			}, 2000)
		}
	};
});