import Util from "../util/util";
import Validations from "app/util/validations";

Util.ifSelector([".landing-page-layout", ".juno-vs-oscar-landing-page-layout"], () =>
{
	$("#demo-submit").on("click", (event) =>
	{
		Util.setRequiredFieldsToInvalid($(".demo-form"));

		const phoneInput = $("input[name='phone_number']")
		if (!Validations.isPhoneValid(phoneInput.val()))
		{
			phoneInput.parent(".mdc-text-field").addClass("mdc-text-field--invalid");
			event.preventDefault();
		}
	});
});